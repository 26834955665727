<template>
  <div id="insta-grid">
    <ul v-if="tumbnails.length > 0">
      <li class="grid-item" v-for="item in tumbnails" :key="item.shortcode" v-scroll-reveal>
        <a :href="`https://www.instagram.com/p/${item.shortcode}/`" target="_blank"><img :src="item.imgUrl" :alt="`Instagram post ${item.shortcode}`"></a>
      </li>
    </ul>
    <ul v-else>
      <li class="grid-item">
        <a href="http://www.trekbikes.com/" target="_blank"><img src="user_data/trek.jpg" alt="trek"></a>
      </li>
      <li class="grid-item">
        <a href="http://www.chamonix.com/pdf/brochureete2015vtt.pdf" target="_blank"><img src="user_data/sentiers.jpg" alt="guide"></a>
      </li>
      <li class="grid-item">
        <a href="https://www.facebook.com/SLASH-Chamonix-1025336777533500/" target="_blank"><img src="user_data/facebook.jpg" alt="facebook"></a>
      </li>
      <li class="grid-item">
        <a href="http://www.redbull.com/en/bike/stories/1331799799442/brandon-semenuk-raw-100-bmx-track-video" target="_blank"><img src="user_data/video1.jpg" alt="video1"></a>
      </li>
      <li class="grid-item">
        <a href="https://www.youtube.com/watch?v=B62HbIFu-vI" target="_blank"><img src="user_data/video2.jpg" alt="video2"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InstaFeed',
  data(){
    return {
      tumbnails: []
    }
  },
  methods:{
    getImagesFromInsta(url){
      this.$http.get(url).then(response => {
        let regEx = /<script type="text\/javascript">window._sharedData.*};<\/script>/gm;
        let data = response.body.match(regEx)[0];

        let shortCodeRegEx = /"shortcode":"[a-zA-Z0-9\-]+"/g;
        let shortCodeRaw = data.match(shortCodeRegEx);

        let URLRegEx = /"thumbnail_src":"[a-zA-Z0-9.:/\-_?=]+"/g;
        let URLRaw = data.match(URLRegEx);

        let tumbnails = [];

        URLRaw.forEach((s,i)=>{
          if(shortCodeRaw[i]){
            tumbnails.push({shortcode: shortCodeRaw[i].slice(13,-1),imgUrl:s.slice(17, -1)});
          }
        });

        this.tumbnails = tumbnails;


      }, response => {
        console.log(response);
      });
    }
  },
  mounted () {
    this.$nextTick(()=>{
      this.getImagesFromInsta('https://www.instagram.com/slashchamonix/');
    });
  }
}
</script>

<style>
  #insta-grid ul{
    list-style: none;
    padding: 0;
    text-align: center;
    width: 65%;
    margin: auto;
  }

  #insta-grid li{
    display: inline-block;
    padding: 5px;
    width: 30%;
    box-sizing: border-box;
    transition: all 0.2s ease;
  }

  #insta-grid li img{
    width: 100%;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  }

  #insta-grid li:hover img{
    transform: scale(0.95);
    box-shadow: 0 4px 6px rgba(50, 50, 93, .31), 0 1px 3px rgba(0, 0, 0, .38);
  }
</style>
