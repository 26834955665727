import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import VueScrollReveal from 'vue-scroll-reveal';

Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal',
  duration: 800,
  scale: 0.8,
  distance: '15px',
  mobile: false,
  delay: 200
});

new Vue({
  render: h => h(App),
}).$mount('#app');
